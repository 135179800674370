<template>
  <b-card>
    <edit-field-header
      :title="title"
      @routerBack="$router.back()"
    />

    <edit-field
      :is-edit="true"
      :fields="fields"
      :row-data="row_data"
      post-api="/callbell/UpdateFacilityCallBell"
      finish-router="callBellFacility"
      permission="CallBell_Menu_Facility"
    >
      <template #facility="scope">
        <SlotFacility :row-data="scope.rowData" :company-id="scope.rowData.companyID"
                      :facility-id="scope.rowData.facilityID" :is-edit="scope.isEdit"
        />
      </template>

      <template #floor="scope">
        <SlotFloor :row-data="scope.rowData" :facility-id="scope.rowData.facilityID"/>
      </template>

      <template #room="scope">
        <SlotRoom :row-data="scope.rowData" :floor-id="scope.rowData.floorID"/>
      </template>

      <template #bed="scope">
        <SlotBed :row-data="scope.rowData" :room-id="scope.rowData.roomID"/>
      </template>
    </edit-field>
  </b-card>
</template>

<script>
import fields, { reloadFieldData } from './fields'
import EditField from '@/components/EditField2'
import EditFieldHeader from '@/components/EditFieldHeader'
import common from '@/common'
import SlotFacility from '@/views/bedExitMonitor/deviceManage/SlotFacility'
import SlotFloor from '@/views/bedExitMonitor/deviceManage/SlotFloor'
import SlotRoom from '@/views/bedExitMonitor/deviceManage/SlotRoom'
import SlotBed from '@/views/bedExitMonitor/deviceManage/SlotBed'

export default {
  name: 'Edit',
  components: {
    EditFieldHeader,
    EditField,
    fields,
    SlotFacility,
    SlotFloor,
    SlotRoom,
    SlotBed
  },
  data() {
    return {
      title: common.getMenuName('callBellFacility') + ' - ' + common.getI18n('common.edit'),
      id: this.$router.currentRoute.params.id,
      fields,
      row_data: {}
    }
  },
  created() {
    reloadFieldData();
  },
  mounted() {
    this.id = common.decrypt(this.id)
    if (this.id === undefined) {
      debugger
      this.$router.push({ path: '/' + this.$route.path.split('/')[1] })
      return false
    }
    common.apiGetData('/callbell/GetFacilityCallBell?callBellID=' + this.id)
      .then(res => {
        this.row_data = res.data.callBell
      })
  }
}
</script>

<style scoped>

</style>
